import styled, {css} from 'styled-components';
import Datetime from 'react-datetime';

const Heading = styled.div`
display: flex;
width: 100%;
margin-top:15px;
.name{
font-family: Rubik;
font-size: 16px;
font-weight: 400;
line-height: 20px;
color:#002f47;
display:flex;
margin:auto;
margin-left:0px;
}
.selectAllRadio{
  display:flex;
  align-items:center;
  margin:auto;
  margin-right:0px;
  span{  width:auto;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #002F47;
    display:flex;
    justify-content:flex-start;
    align-items:center;
    flex-direction:row;
  }
}
`;

const Locationwrapper = styled.div`
display: flex;
width: 100%;
margin-top:15px;
norder-radius:6px;
border: 1px solid rgba(156, 156, 156, 1);
background: linear-gradient(0deg, rgba(156, 156, 156, 0.05), rgba(156, 156, 156, 0.05));
padding: 15px 15px 0px 15px;
flex-wrap:wrap;
.checkBoxWidth{
  width:33.33%;
  display:flex;
  align-items:center;
  margin-bottom:15px;
  span{  width:auto;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #002F47;
    display:flex;
    justify-content:flex-start;
    align-items:center;
    flex-direction:row;
  }
}
`;

const TitleContainerV2 = styled.div`
  width: ${({ width }) => width ? width : '100%'};
  padding: 15px 0px 10px 0px;
  margin: ${({ margin }) => margin};
  // position: relative;
  display:block;
  > div {
    float: left;
    width: 100%;
    position: relative;
  }
  .main{
    display: inline-block;
    width: 100%;
    >span{
      font-size: 12px;
    }
  }
  .outerDiv{
    display: flex;
    >div:first-child{
      width: 70%;
    }
    >div:last-child{
      width: 30%;
      display: flex;
      .padding{
        padding-top: 10px;
      }
      >div:first-child{
        height: 100%;
        width: 20%;
        >label{
          margin: 0;
        }
      }
      >div:last-child{
        width: 80%;
      }
    }
  }

  ${({ addMargin }) => addMargin && css`
    > div {
      margin-top: 10px;
    }
  `}

  @media (max-width: 550px) {
    padding: 30px 15px 30px;
  }

  svg {
    position: absolute;
    top: 15px;
    right: 0;
  }

  .dropdown-menu {
    width:${({ fullWidth }) => fullWidth ? "100%" : "50%"}
 >.active>a:hover {color:black}
  }
  @media (max-width: 1000px){
    width: 100%;
  }
`;

const FieldTitleV2 = styled.span`
  font-size: 16px;
  margin-bottom : 15px;
  letter-spacing: 0px;
  line-height: 20px
  color: #002f47;
  font-family: 'Rubik';
  margin-top: ${({ marginTop }) => marginTop && marginTop};
  width: ${({ fullWidth }) => fullWidth && fullWidth};
  display:block;
  width: ${({ width }) => width ? width : '100%'};
  .day {
    font-size: 18px;
    color: #159fc9;
  }
  >img{
    width:20px;
    height:20px;
    margin-left: 15px;
  }
  .note{
    font-size:16px;
    line-height: 20px
    color: #9c9c9c;
    font-family: 'Rubik';
  }

`;

const DownArrow = styled.span`
 
  float: left;
  position: absolute;
  top: 65px;
  right: 5%;
  cursor: pointer;

`;

const StyledDateTime = styled(Datetime)`
width:100%;
max-width:585px;
  > input {
    padding: 15px;
    width:100%;
    max-width:585px;
    border-radius:6px;
    font-size: 18px;
    height: 50px;
    color: rgb(153,153,153);
    border: 1px solid #cdcbcb;
    position: relative;
    background-color: white !important;
    cursor: pointer;
    font-family:rubik-medium;
    color: rgba(0,47,71,1);
    &:hover, &:focus, &:active {
      outline: none;
      border-color: #cdcbcb;
      box-shadow: none;
     
    }
  }
  > input::placeholder{
font-family:rubik;
  }
  
  .rdtPicker {
    width: 100%;
  }
`;

const ContainerWrapper = styled.div`
 width:100%;
 display:block;
`;

const TitleContainerNew = styled.div`
  // float: left;
  width: ${({ width }) => width ? width : '100%'};
  padding: 15px 0px 10px 0px;
  margin: ${({ margin }) => margin};
  position: relative;
  > div {
    // float: left;
    width: 100%;
    position: relative;
  }
  .main{
    display: inline-block;
    width: 100%;
    >span{
      font-size: 12px;
    }
  }
  .outerDiv{
    display: flex;
    >div:first-child{
      width: 70%;
    }
    >div:last-child{
      width: 30%;
      display: flex;
      .padding{
        padding-top: 10px;
      }
      >div:first-child{
        height: 100%;
        width: 20%;
        >label{
          margin: 0;
        }
      }
      >div:last-child{
        width: 80%;
      }
    }
  }

  ${({ addMargin }) => addMargin && css`
    > div {
      margin-top: 10px;
    }
  `}

  @media (max-width: 550px) {
    padding: 30px 15px 30px;
  }

  svg {
    position: absolute;
    top: 15px;
    right: 0;
  }

  .dropdown-menu {
    width:${({ fullWidth }) => fullWidth ? "100%" : "50%"}
 >.active>a:hover {color:black}
  }
  @media (max-width: 1000px){
    width: 100%;
  }
`;


export { Heading, Locationwrapper, TitleContainerV2, FieldTitleV2, DownArrow, StyledDateTime, ContainerWrapper, TitleContainerNew }