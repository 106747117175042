/* eslint-disable no-console */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { StepsWrapper, Steps } from '../challengeCreate/styles';

class StepsHeading extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTabId: 1,
    }
  }

  stepsHeading = [
    {
      name: 'About The Challenge',
      showArrow: true,
      number: 1
    },
    {
      name: 'Who you want to Invite',
      showArrow: true,
      number: 2
    },
    {
      name: 'Select Type of Reminder',
      showArrow: false,
      number: 3
    }
  ];

  render() {
    const { stepCount } = this.props;
    return (
      <StepsWrapper>
        {this.stepsHeading.map((step, index) =>
          (<Steps key={index} active={index+1<=stepCount?true:false}>
            <div className="wrapper">
              <div className="number">{step.number}</div>
              <div className="heading">{step.name}</div>
            </div>
            {step.showArrow && <div className="arrow">
              <div className="active">
                {index+1<=stepCount?
                  <img src={"/images/stepArrow.svg"} />:
                  <img src={"/images/stepArrow2.svg"} />}
              </div>
            </div>}
          </Steps>))}
      </StepsWrapper>
    );
  }
}

StepsHeading.propTypes = {
  history: PropTypes.object,
  stepCount: PropTypes.number
};

export default (StepsHeading);
