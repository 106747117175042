import styled from 'styled-components';
import { Modal } from 'react-bootstrap';

const LaunchchallengeButton = styled.div`
  width: 100%;
  height: 50px;
  background: white;
  border-radius: 6px;
  display: flex;
  cursor: pointer;
  width: 328px;
  background:linear-gradient(90deg, rgb(0, 111, 188) -1.11%, rgb(127, 67, 255) 97.46%);
  .image{
    width: 15%;
    display: flex;
    padding-left: 3px;
    margin: auto;
    >img{
      max-width: 44px;
      max-height: 44px;
    }
  }
  .text{
    width: 85%;
    display: flex;
    justify-content: center;
    align-items: center;

    font-family: 'Rubik-Medium';
    font-size: 18px;
    line-height: 24px;
    color: white;
  }
`;

const BuddyTabContainer = styled.div`
display: flex;
width: 100%;
background: white;
border-radius: 6px;
.launchButton{
  display:flex;
  margin:auto;
  margin-right: 15px;
}
`;

const CategoryWrapper = styled.div`
display: flex;
width: 100%;
flex-wrap:wrap;
justify-content: flex-start;
margin-top:25px;
`;

const CategoryCard = styled.div`
display:block;
width:calc((100% - 50px) / 3);
margin: ${({margin}) => margin ? margin : '0 0 25px 0'};
.image{
  height: 250px;
  width:100%;
  border-radius: 6px 6px 0 0;
  position: relative;
  >img{
    width:100%;
    height: 100%;
    cursor:pointer;
    border-radius: 6px 6px 0 0;
  }
}
  .imageBackground {
    position: absolute;
    top: 0;
    width: 100%;
    height: 250px;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px 6px 0 0;
    .viewMore {
      font-family: 'Rubik-Medium';
      font-size: 16px;
      line-height: 20px;
      min-width: 180px;
      height: 50px;
      background: ${({background}) => background ? background : '#FD7175'};
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      border-radius: 6px;
      color: #005C87;
      text-align: center;
      padding: 0px 10px;
    }
  }
// }
.categoryNameWrapper{
  min-height:54px;
  border-radius: 0px 0px 6px 6px;
  background:white;
  display:flex;
  align-items: center;
  .activityIcon{
    display: flex;
    margin: auto;
    margin-right: 15px;
    >img{
      width:19px;
      height:24px;
    }
  }
  .wellnessActivityIcon{
    display: flex;
    margin: auto;
    margin-right: 15px;
    >img{
      width:24px;
      height:24px;
    }
  }
  .categoryName{
    max-width:75%;
    font-family: Rubik-Medium;
    font-size: 18px;
    line-height: 24px;
    color: #005C87;
    padding-left: 15px;
    -webkit-line-clamp: 1;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    display: -webkit-box !important;
    overflow: hidden;
  }
  .tooltipIcon{
     width:22px;
     height:22px; 
     margin-left:10px;
     >img{
         width:100%;
         height:100%;
         cursor:pointer;
     }

     .text{
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      display: -webkit-box;
      max-width: 100%;
      text-overflow: ellipsis;
     }
  }
  .challengeCount{
    font-family: Rubik;
    font-size: 14px;
    line-height: 20px;
    color: #F4AAA9;  
    margin:auto;
    margin-right:15px;  
  }
}
.wrapperMain{
  background:white;
  display:flex;
  border-radius: 0px 0px 6px 6px;
  flex-wrap:wrap;
  .adminCards{
    display:flex;
    width:100%;
    padding-bottom: 15px;
  }
.categoryNameWrapper{
  width:100%;
  min-height:54px;
  // border-radius: 0px 0px 6px 6px;
  background:white;
  display:flex;
  align-items: center;
  .categoryName{
    font-family: Rubik-Medium;
    font-size: 18px;
    line-height: 24px;
    color: #005C87;
    padding-left: 15px;
  }
  .tooltipIcon{
     width:22px;
     height:22px; 
     margin-left:10px;
     >img{
         width:100%;
         height:100%;
         cursor:pointer;
     }
  }
  .challengeCount{
    font-family: Rubik;
    font-size: 14px;
    line-height: 20px;
    color: #F4AAA9;  
    margin:auto;
    margin-right:15px;  
  }
}
}
`;

const SeeAllStyledModal = styled(Modal)`
  .modal-dialog {
    width:500px;
    // height:400px;
    .modal-content{
      border-radius: 7px 7px 6px 6px;
    }
    .modal-header {
      background: linear-gradient(180deg,#52A8ED 0%,#8AD3F7 100%);
      border-radius: 6px 6px 0px 0px;
    }
    .modal-footer{
      height:80px;
      display:flex;
      justify-content:center;
      align-items:center;
      
    }
    .modal-body{
      padding: 0px 65px;
      width:100%;
      display:flex;
      justify-content:center;
      align-items:center;
      flex-direction:column;
    }  
`;

const CustomTitle = styled(Modal.Title)`
float: left;
width: 100%;
> div {
  float: left;
  width: 100%;
  display: flex;
  > div {
    font-size: 12px;
    color: #a9a9a9;
    display: flex;
  }

  > span {
    font-size: 18px;
    letter-spacing: 0px;
    font-family: rubik-medium;
    color: white;
    width: 70%;
  }
  .closeImage{
    width: 30%;
    display: flex;
    justify-content: flex-end;
    >img{
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }
}

@media (max-width: 500px) {
  padding: 0 10px;
}
`;

const PopupDetials = styled.div`
display: flex;
width: 100%;
flex-wrap:wrap;
.congrats{
  width:100%;
  text-align:center;
  font-family: Rubik-Medium;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color:#69c2ff;
  padding-top: 25px;
}
.congratssub{
  width:100%;
  font-family: Rubik;
font-size: 18px;
font-weight: 500;
line-height: 20px;
color:#002f47;
padding-top: 15px;
text-align:center;
}
.challengeName{
  width:100%;
  font-family: Rubik-Medium;
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
  color:#002f47;
  padding-top: 15px;
  text-align:center;
  padding: 25px 0px
}
.challengeIcon{
  width:100%;
  height: 250px;
  border-radius:6px;
  >img{
    width:100%;
    height:100%;
    border-radius:6px;
  }
}
.cardWrapper{
  display:flex;
  width:100%;
  margin: 15px 0px 25px 0px;
  .categoryType{
    display:flex;
    align-items: center;
    .categoryImage{
      width:25px;
      height:25px;
      >img{
      width:25px;
      height:25px;}
    }
    .categoryName{
      font-family: Rubik-Medium;
      font-size: 18px;
      font-weight: 500;
      line-height: 20px;
      color:#002f47;
      margin-left:10px;
    }
  }
.question{
  background: #9c9c9c;
  border-radius:3px;
  padding: 5px 10px;
  display: flex;
    margin: auto;
    margin-right: 0px;
  .text{
    font-family: Rubik-Medium;
    font-size: 18px;
    font-weight: 500;
    line-height: 20px;
    color:#69c2ff;
  }
}
}
`;

const TabsWrapper = styled.div`
  display: flex;
  width: 100%;
`;

const TabsButton = styled.div`
  display: flex;
  width: 100%;
  width: ${({width}) => width ? width : '100%'};
  height: ${({height}) => height ? height : '100%'};
  background:${({background}) => background ? background : 'transparent'};
  border:${({border}) => border ? border : '1px solid #005C8799'};
  color:${({color}) => color ? color : 'white'};
  font-family:${({font}) => font ? font : 'Rubik'};
  font-size: 16px;
  line-height: 20px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  cursor: pointer;
  margin:${({margin}) => margin ? margin : '0'};
`;

const PopupWrapper = styled.div`
 width:100%;
 display:flex;
 border-bottom: 1px solid #005C874D;
 background:white;
 flex-wrap:wrap;
 .heading{
  font-family: Rubik-Medium;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: center;
  color: #005C87;
  width:100%;
  display:flex;
  justify-content:center;
  padding: 25px 25px 15px 25px;
 }
 .about{
  font-family: Rubik-Medium;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: center;
  color: #0D4270;
  width:100%;
  display:flex;
  justify-content:center;
  padding: 0px 25px 15px 25px;
 }
 .image{
  width:100%;
  margin-bottom:15px;
  >img{
    width:320px;
    height:200px;
    border-radius:6px;
    margin: auto;
    display: flex;
  }
 }
 .type{
  width:100%;
  display:flex;
  justify-content:center;
  margin-bottom:15px;
  >img{
   width:20px;
   height:24px;
  }
  .name{
    font-family: Rubik;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: center;
    color:#0D4270;
    margin-left:10px;
    align-items:center;
    display:flex;
  }
 }
.aboutChallenge{
  padding:0 25px 5px 25px;
  width:100%;
  justify-content:center;
  font-family: Rubik;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: center;
  color:#0D4270;
}
`;

const ButtonWrapper = styled.div`
width:100%;
display:flex;
padding:0 25px 0 25px;
justify-content: center;
.button{
width: ${({ width, widthValue }) => (widthValue?widthValue:width ? "48%" : "100%")};
font-size: 18px;
font-size: ${({ font }) => (font ? font : "18px")};
line-height: 24px;
font-family: rubik-medium;
color: ${({ disabled }) => (disabled ? 'white' : "white")};
padding: 13px 8px 13px 8px;
padding: ${({ padding }) => (padding ? padding : "13px 8px 13px 8px")};
display: flex;
margin: ${({ margin }) => (margin ? margin : "15px")};
justify-content: center;
border-radius: 3px;
cursor: ${({ cursor }) => (cursor ? "" : "pointer")};
background: ${({ disabled }) =>disabled ? '#005C874D' : "#005C87"};
box-shadow: ${({ disabled }) => (disabled ? '' : "0px 8px 24px 0px #005C8780")};
}
`;

const CreateChallengeCard = styled.div`
width: calc((100% - 50px) / 3);
display:flex;
height:303px;
border: 3px dashed #005C874D;
border-radius:6px;
.button{
  width: 239px;
  height: 50px;
  display:flex;
  margin:auto;
  background: #005C87;
  border-radius:6px;
  font-family: Rubik-Medium;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: center;
  color: white;
  justify-content: center;
  align-items: center;
  cursor:pointer;
}
`;

const CreateChallengeButton = styled.div`
  display: flex;
  width: 190px;
  height:40px;
  border: 1px solid #005C87;
  background: ${({ active }) =>active ? '#005C87' : "transparent"};
  border-radius:6px;
  margin:auto 0px auto auto;
  cursor:pointer;
  .image{
    height:22px;
    width:22px;
    margin:auto 0px auto 10px;
    >svg{
      height:22px;
      width:22px;
      >path{
        fill: ${({ active }) =>active ? 'white' : "#005C87"};
      }
    }
  }
  .text{
    font-family: Rubik-Medium;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    color: ${({ active }) =>active ? 'white' : "#005c87"};
    display:flex;
    justify-content:center;
    align-items:center;
    margin: auto;
  }
`;

export { LaunchchallengeButton, BuddyTabContainer, CategoryWrapper, CategoryCard, CustomTitle, SeeAllStyledModal, PopupDetials, TabsWrapper, TabsButton, PopupWrapper, ButtonWrapper, CreateChallengeCard, CreateChallengeButton }